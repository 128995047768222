var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết phụ huynh góp ý",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("Tiêu đề :"),
            ]),
            _vm._v(
              "\n      " + _vm._s(this.viewContent.feedbackTitle) + "\n    "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("span", { staticStyle: { "font-size": "18px" } }, [
                _vm._v("Nội dung :"),
              ]),
              _c(
                "el-input",
                {
                  attrs: { type: "textarea", rows: "5" },
                  model: {
                    value: this.viewContent.feedbackContent,
                    callback: function ($$v) {
                      _vm.$set(this.viewContent, "feedbackContent", $$v)
                    },
                    expression: "this.viewContent.feedbackContent",
                  },
                },
                [_vm._v(_vm._s(this.viewContent.feedbackContent))]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "file" }, [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("File đính kèm :"),
            ]),
            _c(
              "div",
              {
                staticClass: "chuafile",
                staticStyle: { "margin-bottom": "30px" },
              },
              [
                _c("el-upload", {
                  ref: "upload",
                  staticClass: "upload-demo",
                  staticStyle: { "margin-top": "-30px" },
                  attrs: {
                    multiple: "",
                    "auto-upload": false,
                    limit: 5,
                    "file-list": _vm.viewContent.feedBackFileList,
                    "on-preview": _vm.handleFileRequest,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "reply" }, [
            _c(
              "span",
              { staticStyle: { "font-size": "18px", display: "block" } },
              [_vm._v("Nhà trường trả lời:\n      ")]
            ),
            _vm.viewContent.schoolReplyDel == false
              ? _c("textarea", {
                  staticStyle: { border: "1px solid #dcdfe6", width: "99%" },
                  attrs: {
                    type: "textarea",
                    rows: 5,
                    maxlength: "3000",
                    "show-word-limit": "",
                  },
                  domProps: { value: _vm.newName },
                  on: {
                    keyup: function ($event) {
                      _vm.newName = $event.target.value
                    },
                  },
                })
              : _c("textarea", {
                  staticStyle: { border: "1px solid #dcdfe6", width: "99%" },
                  attrs: {
                    disabled: "",
                    type: "textarea",
                    rows: 5,
                    maxlength: "3000",
                    "show-word-limit": "",
                  },
                  domProps: { value: _vm.newName },
                  on: {
                    keyup: function ($event) {
                      _vm.newName = $event.target.value
                    },
                  },
                }),
          ]),
          _c("div", { staticClass: "xn" }, [
            _c("span", [_vm._v("Xác nhận:")]),
            _vm._v(
              "\n      " +
                _vm._s(this.viewContent.confirmName) +
                " |\n      " +
                _vm._s(_vm._f("formatDateTime")(this.viewContent.confirmDate)) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "tl" }, [
            _c("span", [_vm._v("Trả lời:")]),
            _vm._v(
              "\n      " +
                _vm._s(this.viewContent.replyName) +
                " |\n      " +
                _vm._s(
                  _vm._f("formatDateTime")(this.viewContent.schoolTimeReply)
                ) +
                "\n    "
            ),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-left": "10px" },
              attrs: { type: "danger", icon: "el-icon-circle-close" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton()
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.checkPermission(["news_feedbackParent_update"])
            ? _c(
                "div",
                { staticClass: "btt" },
                [
                  _vm.viewContent.schoolReplyDel == false &&
                  this.newName != "" &&
                  this.newName != null &&
                  _vm.newName == _vm.viewContent.schoolReply &&
                  _vm.viewContent.idSchoolReply != null
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loaddingButton1,
                            icon: "el-icon-folder-remove",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove("feedbackRemove")
                            },
                          },
                        },
                        [_vm._v("Thu hồi trả lời\n      ")]
                      )
                    : _vm.viewContent.schoolReplyDel == false &&
                      this.newName != _vm.viewContent.schoolReply
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: "",
                            icon: "el-icon-folder-remove",
                          },
                        },
                        [_vm._v("Thu hồi trả lời")]
                      )
                    : _vm.viewContent.schoolReplyDel == true &&
                      this.newName != "" &&
                      _vm.viewContent.schoolReply != null
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loaddingButton1,
                            icon: "el-icon-folder-checked",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove1("feedbackRemove1")
                            },
                          },
                        },
                        [_vm._v("Hủy thu hồi")]
                      )
                    : _vm.viewContent.schoolReplyDel == true &&
                      this.newName != "" &&
                      _vm.viewContent.schoolReply == null &&
                      _vm.viewContent.schoolReply != this.newName
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: "",
                            icon: "el-icon-folder-checked",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove1("feedbackRemove1")
                            },
                          },
                        },
                        [_vm._v("Hủy thu hồi")]
                      )
                    : _vm._e(),
                  this.newName != "" &&
                  this.newName != null &&
                  _vm.viewContent.schoolReply != this.newName
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            loading: _vm.loaddingButton2,
                            icon: "el-icon-circle-check",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("feedbackSend")
                            },
                          },
                        },
                        [_vm._v("Gửi phản hồi")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: "",
                            type: "success",
                            icon: "el-icon-circle-check",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("feedbackSend")
                            },
                          },
                        },
                        [_vm._v("Gửi phản hồi")]
                      ),
                  !_vm.viewContent.schoolConfirmStatus
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingButton3,
                            icon: "el-icon-circle-check",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm1("feedbackConfirm")
                            },
                          },
                        },
                        [_vm._v("Xác nhận")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            disabled: "",
                            icon: "el-icon-circle-check",
                          },
                        },
                        [_vm._v("Xác nhận")]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }